// gatsby/src/hooks/useMembershipRegistrationInit.ts

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';

const useMembershipRegistrationInit = () => {
    const formValues = useSelector(membershipRegistrationFormValuesSelector);

    const onInit = useCallback(() => {
        // checks for Uninsured : Step 1 : value(s)
        if (formValues?.email && formValues?.zipcode) {
            return true;
        }

        return false;
    }, [formValues]);

    const onInitInsured = useCallback(() => {
        // checks for Insured
        if (formValues?.formPersonalInfo?.firstName && formValues?.insuranceForm?.insuranceId) {
            return true;
        }

        return false;
    }, [formValues]);

    return { onInit, onInitInsured };
};

export default useMembershipRegistrationInit;
